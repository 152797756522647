import {css, html, unsafeCSS} from 'lit';
import {Directive, directive, PartType} from 'lit/directive.js';
import {unsafeHTML} from "lit/directives/unsafe-html.js";


if (!window.gettext) {
    console.warn("window.gettext() missing");
}

class GetTextDirective extends Directive {
    // Directive that allows HTML (including tags and entities) to be used in translated strings,
    // as long as they're not in an attribute.
    constructor(partInfo) {
        super(partInfo);
        this.needsEscape = partInfo.type !== PartType.CHILD;
        // FIXME Perhaps reject use as PartType.ATTRIBUTE except for appropriate attribute names
        // e.g. alt, title, placeholder, value... any others?
    }

    render(source) {
        const translation = window.gettext?.(source) ?? source;
        return this.needsEscape ? translation : unsafeHTML(translation);
    }
}

class PGetTextDirective extends Directive {
    // (Another) Directive that allows HTML (including tags and entities) to be used in translated strings,
    // as long as they're not in an attribute.
    constructor(partInfo) {
        super(partInfo);
        this.needsEscape = partInfo.type !== PartType.CHILD;
        // FIXME Perhaps reject use as PartType.ATTRIBUTE except for appropriate attribute names
        // e.g. alt, title, placeholder, value... any others?
    }

    render(context, source) {
        const translation = window.pgettext?.(context, source) ?? source;
        return this.needsEscape ? translation : unsafeHTML(translation);
    }
}
export const gettext = directive(GetTextDirective);
export const pgettext = directive(PGetTextDirective);

export function url(url_string) {
    // Shorthand for inserting URL string as safe (but maybe invalid) CSS image value
    return unsafeCSS(url_string ? `url("${CSS.escape(url_string)}")` : unsafeCSS(''));
}

// We need to emulate interpolate(fmt, obj, named) but allow fmt to be EITHER a string or the result of a directive call;
// then the return value has to be html (This could be a directive if we need it to treat the argument as plain text
// when the call appears in attributes etc.)
export function interpolate(fmt, obj, named) {
    if (typeof fmt !== 'string') {
        if (!fmt.values || fmt.values.length !== 1) {
            throw new Error("interpolate() needs a gettext() result or similar as an input fmt");
        }
        fmt = fmt.values[0];
    }
    return unsafeHTML(window.interpolate(fmt, obj, named));
}

